import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import { getDiff, getDiffPercentage } from "~/helpers/portfolio/portfolio-opex-helpers";
import { hasValue } from "~/helpers/common-helpers";
export var useOpexTableHelpers = function useOpexTableHelpers(preferences) {
  var dataDisplayValue = computed(function () {
    var benchmarkSetting = preferences.value.benchmark;
    return function (data) {
      if (!data) return 0;
      switch (benchmarkSetting) {
        case "average.total":
          return data.resultNormal;
        case "budget.diff":
          return getDiff(data.resultNormal, data.budgetNormal);
      }
    };
  });
  var target = computed(function () {
    var reference = hasValue(preferences.value.referenceMonthOffset);
    var benchmarkSetting = preferences.value.benchmark;
    return function (row, propertyId) {
      var data = row.current.propertiesData.get(propertyId);
      if (!data) return 0;
      switch (benchmarkSetting) {
        case "average.total":
          return data.resultNormal;
        case "budget.diff":
          return reference ? getDiff(data.resultNormal, data.budgetNormal) : data.resultNormal;
      }
    };
  });
  var benchmark = computed(function () {
    var reference = hasValue(preferences.value.referenceMonthOffset);
    var benchmarkSetting = preferences.value.benchmark;
    return function (row, propertyId) {
      var _row$reference, _dataReference$result;
      var dataCurrent = row.current.propertiesData.get(propertyId);
      var dataReference = (_row$reference = row.reference) === null || _row$reference === void 0 ? void 0 : _row$reference.propertiesData.get(propertyId);
      if (!dataCurrent) return 0;
      switch (benchmarkSetting) {
        case "average.total":
          return reference ? (_dataReference$result = dataReference === null || dataReference === void 0 ? void 0 : dataReference.resultNormal) !== null && _dataReference$result !== void 0 ? _dataReference$result : 0 : row.current.average.resultNormal;
        case "budget.diff":
          return reference ? dataReference ? getDiff(dataReference.resultNormal, dataReference.budgetNormal) : 0 : dataCurrent.budgetNormal;
      }
    };
  });
  var diff = computed(function () {
    var _target = target.value;
    var _benchmark = benchmark.value;
    return function (row, propertyId) {
      return getDiff(_target(row, propertyId), _benchmark(row, propertyId));
    };
  });
  var diffPercentage = computed(function () {
    var _target = target.value;
    var _benchmark = benchmark.value;
    return function (row, propertyId) {
      return getDiffPercentage(_target(row, propertyId), _benchmark(row, propertyId));
    };
  });
  var referencedDisplayValue = computed(function () {
    var referenceSet = hasValue(preferences.value.referenceMonthOffset);
    var referencePercentageSetting = referenceSet && preferences.value.referencePercentage;
    return function (current, reference) {
      return (referenceSet ? -1 : 1) * (referencePercentageSetting ? getDiffPercentage(current, reference) : getDiff(current, reference));
    };
  });
  var tablePropertyDisplayValue = computed(function () {
    var _dataDisplayValue = dataDisplayValue.value;
    var _referencedDisplayValue = referencedDisplayValue.value;
    return function (row, propertyId) {
      var _row$reference2;
      var currentData = row.current.propertiesData.get(propertyId);
      var referenceData = (_row$reference2 = row.reference) === null || _row$reference2 === void 0 ? void 0 : _row$reference2.propertiesData.get(propertyId);
      return _referencedDisplayValue(_dataDisplayValue(currentData), _dataDisplayValue(referenceData));
    };
  });
  var tableAverageDisplayValue = computed(function () {
    var _dataDisplayValue = dataDisplayValue.value;
    var _referencedDisplayValue = referencedDisplayValue.value;
    return function (row) {
      var _row$reference3;
      return _referencedDisplayValue(_dataDisplayValue(row.current.average), _dataDisplayValue((_row$reference3 = row.reference) === null || _row$reference3 === void 0 ? void 0 : _row$reference3.average));
    };
  });
  var isZeroRow = computed(function () {
    var isZeroValue = function isZeroValue(v) {
      return isNaN(v) || Math.round(v * 1000) / 1000 === 0;
    };
    return function (row) {
      var _isZero = function _isZero(data) {
        if (!hasValue(data)) return true;
        var properties = Array.from(data === null || data === void 0 ? void 0 : data.propertiesData.values());
        var average = data.average;
        var isZero = isZeroValue(average.budgetNormal) && isZeroValue(average.resultNormal) && properties.every(function (property) {
          return isZeroValue(property.budgetNormal) && isZeroValue(property.resultNormal);
        });
        return isZero;
      };
      return _isZero(row.current) && _isZero(row.reference);
    };
  });
  return {
    dataDisplayValue: dataDisplayValue,
    diff: diff,
    diffPercentage: diffPercentage,
    tableAverageDisplayValue: tableAverageDisplayValue,
    tablePropertyDisplayValue: tablePropertyDisplayValue,
    isZeroRow: isZeroRow
  };
};