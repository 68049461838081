import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getSetValue } from "~/helpers/portfolio/portfolio-category-helpers";
import { arrayLast } from "~/helpers/common-helpers";
import { useOpexData } from "./useOpexData";
export var useOpexTableData = function useOpexTableData(preferences, variables) {
  var opexDataCurrent = useOpexData(preferences, computed(function () {
    return _objectSpread({}, variables.value);
  }), false);
  var opexDataReference = useOpexData(preferences, computed(function () {
    return _objectSpread({}, variables.value);
  }), true);
  var getRows = computed(function () {
    return function () {
      var _opexDataReference$va, _arr$find;
      if (opexDataCurrent.value.loading.value || (_opexDataReference$va = opexDataReference.value) !== null && _opexDataReference$va !== void 0 && _opexDataReference$va.loading.value) return [];
      var categories = new Map();
      var value = function value(data, key) {
        var _arrayLast$accumulate, _arrayLast, _data$columns$key;
        return (_arrayLast$accumulate = (_arrayLast = arrayLast((_data$columns$key = data === null || data === void 0 ? void 0 : data.columns[key]) !== null && _data$columns$key !== void 0 ? _data$columns$key : [])) === null || _arrayLast === void 0 ? void 0 : _arrayLast.accumulated) !== null && _arrayLast$accumulate !== void 0 ? _arrayLast$accumulate : 0;
      };
      var getGroupAverage = function getGroupAverage(averageOpexData, averageData) {
        var normal = preferences.value.normaliseBy === "area" ? averageOpexData.entityData.totalArea : preferences.value.normaliseBy === "tenancies" ? averageOpexData.entityData.totalTenancies : averageOpexData.entityCount;
        return {
          area: averageOpexData.entityData.totalArea,
          tenancies: averageOpexData.entityData.totalTenancies,
          budgetTotal: value(averageData, "budget"),
          budgetNormal: value(averageData, "budget") / (normal || 1),
          resultTotal: value(averageData, "result"),
          resultNormal: value(averageData, "result") / (normal || 1),
          areaUnit: averageOpexData.entityData.areaUnit
        };
      };
      var setGroupProperty = function setGroupProperty(data, propertyCategoryData, categoryData, averageOpexData) {
        var _propertyCategoryData, _propertyCategoryData2, _propertyCategoryData3;
        var tenancies = (_propertyCategoryData = (_propertyCategoryData2 = propertyCategoryData.property.assetManagementTenancies) === null || _propertyCategoryData2 === void 0 ? void 0 : (_propertyCategoryData3 = _propertyCategoryData2.items) === null || _propertyCategoryData3 === void 0 ? void 0 : _propertyCategoryData3.length) !== null && _propertyCategoryData !== void 0 ? _propertyCategoryData : 0;
        var area = propertyCategoryData.area;
        var normal = preferences.value.normaliseBy === "area" ? area : preferences.value.normaliseBy === "tenancies" ? tenancies : 1;
        data.propertiesData.set(propertyCategoryData.property.id, {
          area: area,
          tenancies: tenancies,
          budgetTotal: value(categoryData, "budget"),
          budgetNormal: value(categoryData, "budget") / (normal || 1),
          resultTotal: value(categoryData, "result"),
          resultNormal: value(categoryData, "result") / (normal || 1),
          areaUnit: averageOpexData.entityData.areaUnit
        });
      };
      var _loop = function _loop() {
        var _opexDataReference$va2;
        var referenceSet = !!opexDataReference.value;
        var currentPropertyCategoryData = opexDataCurrent.value.propertyOpexBenchmarks.value[i];
        var referencePropertyCategoryData = (_opexDataReference$va2 = opexDataReference.value) === null || _opexDataReference$va2 === void 0 ? void 0 : _opexDataReference$va2.propertyOpexBenchmarks.value[i];
        var _loop2 = function _loop2() {
          var _opexDataReference$va3;
          var currentCategoryData = currentPropertyCategoryData.categories[c];
          var referenceCategoryData = referencePropertyCategoryData === null || referencePropertyCategoryData === void 0 ? void 0 : referencePropertyCategoryData.categories[c];
          var currentCategoryAverageData = opexDataCurrent.value.averageData.value.find(function (row) {
            return row.id === currentCategoryData.id;
          });
          var referenceCategoryAverageData = (_opexDataReference$va3 = opexDataReference.value) === null || _opexDataReference$va3 === void 0 ? void 0 : _opexDataReference$va3.averageData.value.find(function (row) {
            return row.id === (referenceCategoryData === null || referenceCategoryData === void 0 ? void 0 : referenceCategoryData.id);
          });
          var group = getSetValue(currentCategoryData.id, categories, function () {
            return {
              current: {
                average: getGroupAverage(opexDataCurrent.value.averageOpexData.value, currentCategoryAverageData),
                propertiesData: new Map()
              },
              reference: referenceSet ? {
                average: getGroupAverage(opexDataReference.value.averageOpexData.value, referenceCategoryAverageData),
                propertiesData: new Map()
              } : undefined,
              id: currentCategoryData.id,
              name: currentCategoryData.name,
              currency: currentCategoryData.currency
            };
          });
          setGroupProperty(group.current, currentPropertyCategoryData, currentCategoryData, opexDataCurrent.value.averageOpexData.value);
          if (referenceSet && group.reference && referencePropertyCategoryData && referenceCategoryData) setGroupProperty(group.reference, referencePropertyCategoryData, referenceCategoryData, opexDataReference.value.averageOpexData.value);
        };
        for (var c = 0; c < currentPropertyCategoryData.categories.length; c++) {
          _loop2();
        }
      };
      for (var i = 0; i < opexDataCurrent.value.propertyOpexBenchmarks.value.length; i++) {
        _loop();
      }
      var arr = Array.from(categories.values());
      opexDataCurrent.value.currency.value = (_arr$find = arr.find(function (r) {
        return !!r.currency;
      })) === null || _arr$find === void 0 ? void 0 : _arr$find.currency;
      return arr;
    };
  });
  var tableRows = computed(function () {
    return getRows.value();
  });
  var loading = computed(function () {
    var _opexDataReference$va4;
    return opexDataCurrent.value.loading.value || ((_opexDataReference$va4 = opexDataReference.value) === null || _opexDataReference$va4 === void 0 ? void 0 : _opexDataReference$va4.loading.value);
  });
  return {
    currency: opexDataCurrent.value.currency,
    areaUnit: opexDataCurrent.value.areaUnit,
    loading: loading,
    tableRows: tableRows,
    properties: opexDataCurrent.value.properties
  };
};