// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_BrcEp{display:flex;flex-direction:column;height:100%}.row_niS0G{align-items:center;display:flex}.grid_qwJjL{background-color:#fff;display:grid;grid-template-columns:auto auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_BrcEp",
	"row": "row_niS0G",
	"grid": "grid_qwJjL"
};
module.exports = ___CSS_LOADER_EXPORT___;
