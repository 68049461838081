// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Czm18{display:flex;flex-direction:column;height:100%}.row_juvzg{align-items:center;display:flex}.grid_5fr\\+b{background-color:#fff;display:grid;grid-template-columns:auto auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Czm18",
	"row": "row_juvzg",
	"grid": "grid_5fr+b"
};
module.exports = ___CSS_LOADER_EXPORT___;
