// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_dvPLR{max-width:48rem;min-width:24rem;overflow:hidden}.text_KQSGr{white-space:pre-line!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_dvPLR",
	"text": "text_KQSGr"
};
module.exports = ___CSS_LOADER_EXPORT___;
