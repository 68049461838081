// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_klBXT{max-width:48rem;min-width:24rem;overflow:hidden}.text_J5aJd{white-space:pre-line!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_klBXT",
	"text": "text_J5aJd"
};
module.exports = ___CSS_LOADER_EXPORT___;
