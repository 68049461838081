import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.find.js";
import moment from "moment";
import { arrayLast, objectFromKeys } from "~/helpers/common-helpers";
export var getPeriodLabel = function getPeriodLabel(columns, periodRangeType) {
  var periodFrom = columns[0];
  var periodTo = arrayLast(columns);
  if (!periodFrom || !periodTo) return "";
  switch (periodRangeType) {
    case "yearRolling":
      return filter.capitalizeAll("".concat(filter.date.range(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))));
    case "yearToDate":
      return filter.capitalizeAll("".concat(filter.date.range(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))));
    case "yearWhole":
      return "".concat(filter.date.range(moment.utc(periodFrom).startOf("year").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("year").format("YYYY-MM-DD")));
  }
};
export var OpexSettingsInjectionKey = Symbol("opexSettingsInjectionKey");
export var periodRangeOptions = [{
  id: "yearWhole",
  name: "PORTFOLIO_VIEW_OPEX_PERIOD_WHOLE_YEAR"
}, {
  id: "yearRolling",
  name: "PORTFOLIO_VIEW_OPEX_PERIOD_LATEST_12"
}, {
  id: "yearToDate",
  name: "PORTFOLIO_VIEW_OPEX_PERIOD_YEAR_TO_DATE"
}];
export var opexSavedSettingsFields = ["benchmark", "excludePropertyIds", "includeZeroRows", "normaliseBy", "periodOffset", "periodRangeType", "referenceMonthOffset", "referencePercentage"];
export var settingsTemplateOptions = [{
  id: "normLatest12",
  name: "normLatest12",
  settings: {
    benchmark: "average.total",
    excludePropertyIds: ["companies"],
    includeZeroRows: false,
    normaliseBy: "area",
    periodOffset: 0,
    periodRangeType: "yearRolling",
    referenceMonthOffset: undefined,
    referencePercentage: false
  }
}, {
  id: "normDevelopment12Percentage",
  name: "normDevelopment12Percentage",
  settings: {
    benchmark: "average.total",
    excludePropertyIds: ["companies"],
    includeZeroRows: false,
    normaliseBy: "area",
    periodOffset: 0,
    periodRangeType: "yearRolling",
    referenceMonthOffset: -12,
    referencePercentage: true
  }
}, {
  id: "budgetYTD",
  name: "budgetYTD",
  settings: {
    benchmark: "budget.diff",
    excludePropertyIds: ["companies"],
    includeZeroRows: false,
    normaliseBy: "area",
    periodOffset: 0,
    periodRangeType: "yearToDate",
    referenceMonthOffset: undefined,
    referencePercentage: false
  }
}];
export var OPEX_TEMPLATE_DEFAULT = "normLatest12";
export var getSelectedTemplate = function getSelectedTemplate(preferences) {
  var _preferences$savedTem;
  return (_preferences$savedTem = preferences === null || preferences === void 0 ? void 0 : preferences.savedTemplates.find(function (template) {
    return template.id === preferences.template;
  })) !== null && _preferences$savedTem !== void 0 ? _preferences$savedTem : settingsTemplateOptions.find(function (template) {
    return template.id === (preferences === null || preferences === void 0 ? void 0 : preferences.template);
  });
};
export var settingsTemplateOptionsTranslationKeys = {
  budgetYTD: "PORTFOLIO_OPEX_TEMPLATE_BUDGET_YTD",
  normLatest12: "PORTFOLIO_OPEX_TEMPLATE_NORM_ROLLING",
  normDevelopment12Percentage: "PORTFOLIO_OPEX_TEMPLATE_NORM_DEVELOPMENT_12_PERCENTAGE"
};
export var sanitizeOpexSettings = function sanitizeOpexSettings(preferences) {
  return objectFromKeys.apply(void 0, [preferences].concat(opexSavedSettingsFields));
};