import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getSelectedTemplate, OPEX_TEMPLATE_DEFAULT } from "../helpers";
export var useOpexPreferences = function useOpexPreferences() {
  var preferencesQuery = usePreferencesNamespace("preferencesNamespacePortfolioOpex");
  var preferences = computed(function () {
    return preferencesQuery.result.value;
  });
  var templateId = computed(function () {
    var _preferences$value;
    return (_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : _preferences$value.template;
  });
  var template = computed(function () {
    return getSelectedTemplate(preferences.value);
  });
  var result = computed(function () {
    var _template$value$setti, _template$value, _templateId$value;
    if (!preferences.value) return null;
    if (preferences.value.templateModified) return preferences.value;
    var settings = (_template$value$setti = (_template$value = template.value) === null || _template$value === void 0 ? void 0 : _template$value.settings) !== null && _template$value$setti !== void 0 ? _template$value$setti : preferences.value;
    return _objectSpread(_objectSpread({}, settings), {}, {
      template: (_templateId$value = templateId.value) !== null && _templateId$value !== void 0 ? _templateId$value : OPEX_TEMPLATE_DEFAULT,
      savedTemplates: preferences.value.savedTemplates,
      templateModified: preferences.value.templateModified
    });
  });
  return {
    result: result,
    update: preferencesQuery.update,
    loading: preferencesQuery.loading
  };
};